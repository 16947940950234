.appointment_modal {
    overflow: auto;
    height: 100%;
}

.appointment_modal_content {
    padding: 20px 40px;
}

.header {
    width: 100%;
    background-image: linear-gradient(to right, rgba(103, 219, 248, 0.914), #0844CC);
    color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header_button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    color: #FFAAA5;
    font-size: 1.7rem;
}

.section_header {
    margin-bottom: 2px;
    color: darkblue;
}


.delete_button {
    text-align: right;
    padding-right: 40px;
    margin-top: 30px;
}

.delete_button button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    color: rgba(255, 0, 0, 0.564);
}



/* Client Details */
.client_details {
    margin-bottom: 30px;
}

.client_details_content {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.147);
    border-radius: 10px;
    padding: 2%;
}

.client_photo {
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}

.client_photo img {
    width: 100%;
    height: 100%;
}

.client_info_content, 
.client_phone, 
.client_email {
    display: flex;
    align-items: center;
}

.client_phone { margin-right: 10px; }
.client_phone span, .client_email span {
    font-size: 0.6rem;
    opacity: 0.5;
}

.client_info_icon {
    width: 25px;
    height: 25px;
    background-image: linear-gradient(to right, darkblue, rgba(0, 0, 139, 0.292));
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;
    color: white;
}

.resend_email_button {
    background-image: linear-gradient(to right, #a7727da4, #A7727D, #a7727da4);
    border: none;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    position: absolute;
    right: 2%; 
    top: 15%;
    cursor: pointer;
    color: white;
    font-size: 0.7rem;
    padding: 4px 15px;
}

.client_note {
    color: #D91656;
    font-size: 0.9rem;
    font-weight: bold;
    margin-top: 5px;
}


/* Appointment Details */
.appointment_details {
    margin-bottom: 30px;
}

.appointment_details_content {
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.147);
    border-radius: 10px;
    padding: 3%;
}

.appointment_basic_info {
    padding-right: 30px;
    border-right: 1px solid rgba(0, 0, 0, 0.197);
}

.appointment_basic_info h5 {
    margin-bottom: 10px;
}


.appointment_activity {
    margin-bottom: 10px;
}

.appointment_activity button {
    background: none;
    border: none;
    color: blue;
    opacity: 0.6;
    font-size: 0.7rem;
    cursor: pointer;
}

.appointment_activity ul {
    list-style: none;
}

.appointment_activity ul li {
    font-size: 0.7rem;
    opacity: 0.5;
    margin-bottom: 4px;
}

.appointment_buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 15px 10px;
    padding: 10px 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.093);
}

.appointment_buttons button {
    border: none;
    border-radius: 20px;
    cursor: pointer;
    color: white;
    margin-right: 10px;
    padding: 5px 20px;
    font-size: 0.7rem;
}

.reschedule_button { 
    background-image: linear-gradient(to right, darkblue, rgba(0, 0, 139, 0.507)); 
}

.cancel_button { 
    background-image: linear-gradient(to right, #EF9A53, #E97777); 
}

.change_treatment_button { 
    background-image: linear-gradient(to right, #432E54, #432e5484); 
}

.noshow_button { 
    background-image: linear-gradient(to right, #0E5E6F, #3A8891); 
}



.appointment_status_info {
    padding-left: 20px;
}

.appointment_status_info h5 {
    margin-bottom: 20px;
}

.appointment_status_info span {
    background-color: #61876e41;
    border-radius: 20px;
    padding: 7px 35px;
    color: green;
}

.appointment_status_info p {
    color: orangered;
    font-size: 0.9rem;
    margin-top: 20px;
}




/* Private Note */
.appointment_note { margin-bottom: 30px; }
.appointment_note_content { 
    border: 1px solid rgba(0, 0, 0, 0.137); 
    border-radius: 10px;
}

.appointment_note_text {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2%;
}

.appointment_note_text p {
    font-size: 0.8rem;
    margin-right: 40px;
    opacity: 0.8;
}

.appointment_note_buttons {
    display: flex;
    gap: 15px;
    padding-left: 10px;
    padding-bottom: 10px;
}

.appointment_note_buttons button {
    border: none;
    border-radius: 5px;
    background: none;
    cursor: pointer;
    font-size: 1.05rem;
    color: darkblue;
}

.appointment_note_edit_button {
   border: none;
   background: none;
   cursor: pointer;
   font-size: 1.05rem;
}


/* Payment Status */
.payment_status {
    margin-bottom: 30px;
}

.payment_status_content {
    border: 1px solid rgba(0, 0, 0, 0.147);
    border-radius: 10px;
    padding: 2% 1%;
}

.payment_info {
    display: flex;
    align-items: center;
   
}

.payment_status_text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
}

.payment_status_text span {
    background-color: #61876e41;
    border-radius: 20px;
    padding: 7px 30px;
    color: green;
}

.payment_breakdown {
    width: 70%;
    border-left: 1px solid rgba(0, 0, 0, 0.147);
    padding-left: 30px;
}

.payment_breakdown table {
    width: 100%;
}

.payment_breakdown table tr {
    display: flex;
    align-items: flex-start;
}

.payment_breakdown table td {
    padding: 2px;
    font-size: 0.8rem;
    text-align: left;
}

.payment_breakdown table td:nth-child(odd) {
    width: 65%;
    font-weight: bold;
}

.payment_breakdown table td:nth-child(even) {
    width: 35%;
}

.add_payment {
    border-top: 1px solid rgba(0, 0, 0, 0.147);
    padding: 5px 10px 0px 5px;
    margin-top: 10px;
}

.add_payment_content {
    display: flex;
    justify-content: flex-start;
}

.add_payment_inputs {
    display: flex;
    align-items: center;
}

.add_payment_button {
    width: 100px;
    padding-top: 5px;
}

.add_payment button {
    width: 100%;
    height: 38px;
    background-image: linear-gradient(to right, rgba(0, 0, 139, 0.418), darkblue);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: white;
}








/* Redeem Coupon And Gift Card */
.coupon_gift_card {
    display: flex;
    align-items: center;
}

.redeem_coupon, 
.redeem_gift_card {
    display: flex;
    align-items: flex-start;
}

.redeem_coupon {
    margin-right: 50px;
}

.coupon_gift_card button {
    height: 40px;
    background-image: linear-gradient(to right, rgba(0, 0, 139, 0.418), darkblue);
    border: none;
    border-radius: 20px;
    cursor: pointer;
    color: white;
    padding: 0px 30px;
    font-size: 1.2rem;
    margin-left: -20px;
}





/* Activity By Modal */
.activity_confirm_button {
    margin-top: 40px;
    text-align: center;
}

.activity_confirm_button button {
    border: none;
    border-radius: 20px;
    background-image: linear-gradient(to right, rgba(0, 0, 139, 0.418), darkblue);
    cursor: pointer;
    color: white;
    padding: 7px 30px;
}



/* Select Date For Rescheduling */
.select_date {
    width: 100%;
    margin-bottom: 20px;
}

.select_date label {
    display: block;
    margin-bottom: 2px;
    font-weight: bold;
}

.select_date_input input[type=text] {
    width: 100%;
    background-color: #E7F6F2;
    border-radius: 4px;
    padding: 10px 15px;
}






@media(max-width: 1000px) {

    .appointment_modal_content {
        padding: 20px 15px;
    }
    .header { font-size: 0.8rem; }
    .header_button {
        font-size: 1.5rem;
    }
    .client_photo {
        width: 50px;
        height: 50px;
    }

    .appointment_details_content {
        flex-direction: column-reverse;
    }

    .appointment_basic_info {
        border-right: none;
    }

    .appointment_status_info {
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 0px;
        margin-bottom: 20px;
    }

    .appointment_status_info h5 {
        margin-bottom: 0px;
    }

    .appointment_status_info span {
        padding: 7px 25px;
        font-size: 0.8rem;
    }
    
    .appointment_buttons button {
        margin-right: 7px;
        padding: 5px 15px;
    }

    .payment_breakdown {
        width: 60%;
        padding-left: 10px;
    }
    .payment_status_text {
        width: 40%;
    }

    .payment_status_text span {
        font-size: 0.8rem;
        padding: 5px 15px;
    }
    
    .add_payment_content {
        display: block;
    }

    .add_payment_inputs {
        margin-bottom: 10px;
    }

    .add_payment button {
        display: block;
        width: 100%;
    }

    .coupon_gift_card {
        display: block;
    }
    .redeem_coupon {
        margin-right: 0px;
        margin-bottom: 10px;
    }
}